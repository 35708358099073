div#skills .container {
  padding: 1.5em;
  font-family: "Hack";
}
div#skills .container .skill {
  margin: auto;
  text-align: center;
  border: 4px solid #62E3FF;
  padding: 3em;
  margin: 3em;
}
div#skills .container .skill .skill-icon {
  display: flex;
  justify-content: center;
}
div#skills .container .skill .skill-icon img {
  width: 5.5em;
  height: 5.5em;
}
div#skills .container .skill .skill-name {
  color: #62E3FF;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
div#skills .container .skillbar {
  margin-bottom: 2em;
  font-size: 1.3em;
}
div#skills .container .skillbar .skillbar-name {
  color: white;
}
div#skills .container .skillbar .skillbar-name h4 {
  margin-bottom: 1em;
}
div#skills .container .skillbar .skillbar-bar .skillbar-lang {
  color: #BEBEBE;
  font-size: 0.77em;
  line-height: 1.4;
}

@media only screen and (min-width: 800px) {
  div#skills .container {
    padding: 6.5em;
    max-width: 1080px;
    margin: auto;
  }
  div#skills .container .skills-container {
    display: flex;
    justify-content: space-evenly;
  }
  div#skills .container .skills-container .skill {
    margin: 0.5em;
    border: 3px solid #62E3FF;
    padding: 2em;
    width: 10em;
  }
  div#skills .container .skills-container .skill .skill-icon img {
    width: 5.5em;
    height: 5.5em;
  }
  div#skills .container .skills-container .skill .skill-name {
    font-size: 1em;
    color: #62E3FF;
    margin-bottom: 0;
  }
  div#skills .container .skillbar-container {
    margin-top: 3em;
    display: flex;
  }
  div#skills .container .skillbar-container .skillbar-col {
    font-size: 1em;
    width: 40%;
    margin: 1em auto 2em auto;
  }
  div#skills .container .skillbar-container .skillbar-col .skillbar-name {
    color: white;
  }
  div#skills .container .skillbar-container .skillbar-col .skillbar-name h4 {
    margin-bottom: 1em;
  }
}/*# sourceMappingURL=skills.css.map */