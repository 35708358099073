$bg: #002E56;
$accent: #62E3FF;
$primary: #fff;
$secondary: #BEBEBE;
$tertiary: #A6A6A6;
$brighter: #D6D6D6;
$bg-2: #003F77;

div#skills {
    
    .container {
        padding: 1.5em;
        font-family: 'Hack';

        .skill {
            margin: auto;
            text-align: center;
            border: 4px solid $accent;
            padding: 3em;
            margin: 3em;

            .skill-icon {
                display: flex;
                justify-content: center;

                img {
                    width: 5.5em;
                    height: 5.5em;
                }
            }


            .skill-name {
                color: $accent;
                margin-bottom: 0;
                display: flex;
                justify-content: center;
            }


        }

        .skillbar {
            margin-bottom: 2.0em;
            font-size: 1.3em;

            .skillbar-name {
                color: white;

                h4 {
                    margin-bottom: 1em;
                }
            }

            .skillbar-bar {

                .skillbar-lang {
                    color: $secondary;
                    font-size: 0.77em;
                    line-height: 1.4;

                }
            }

        }

    }

}


@media only screen and (min-width: 800px) {

    div#skills {
        
        .container {
            padding: 6.5em;
            max-width: 1080px;
            margin: auto;

            .skills-container {
                display: flex;
                justify-content: space-evenly;

                .skill {
                    margin: 0.5em;
                    border: 3px solid $accent;
                    padding: 2em;
                    width: 10em;

                    .skill-icon {

                        img {
                            width: 5.5em;
                            height: 5.5em;
                        }
                    }


                    .skill-name {
                        font-size: 1em;
                        color: $accent;
                        margin-bottom: 0;
                    }


                }


            }

            .skillbar-container {
                margin-top: 3em;
                display: flex;

                .skillbar-col {

                    font-size: 1.0em;
                    width: 40%;
                    margin: 1em auto 2em auto;

                    .skillbar-name {
                        color: white;

                        h4 {
                            margin-bottom: 1em;
                        }
                    }
                }
            }
        }
    }
}