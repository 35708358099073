$bg: #002E56;
$accent: #62E3FF;
$primary: #fff;
$secondary: #BEBEBE;
$tertiary: #A6A6A6;
$brighter: #D6D6D6;
$bg-2: #003F77;
$hover-bg: #00539b;

div.awards {

    .container {
        padding: 1.5em;

        .award {
            text-align: center;
            background-color: $bg-2;
            padding: 2em;
            margin: 2em;
            border-radius: 10px;
            transition: 0.2s;
            top: 0;
            position: relative;

            h3.award-title {
                margin: 0;
                color: white;
            }

            p.award-issuer {
                color: $accent;
                font-weight: bold;
                font-family: 'Hack';
                font-size: 1.1em;
                margin-top: 0.6em;
                margin-bottom: 0.6em;
            }

            p.award-date {
                margin: 0;
                color: $brighter;
                font-family: 'Hack';
            }

            p.award-blurb {
                color: $brighter;
                margin-bottom: 0;
                margin-top: 1.4em;
                text-align: left;
            }


            &:hover {
                transition: 0.2s;
                top: -10px;
                background-color: $hover-bg;
            }
        }
    }
}


@media only screen and (min-width: 800px) {

    div.awards {

        .container {
            padding: 4.0em;

            .award-row {
                max-width: 20em;
                display: flex;
                justify-content: space-evenly;
                margin: auto auto 1.0em auto;

                .award {
                    width: 13em;
                    height: 16.5em;
                    padding: 1.8em;
                }
            }
        }
    }
}


@media only screen and (min-width: 1080px) {

    div.awards {

        .container {

            .award-row {
                max-width: 70em;

                .award {
                    width: 18em;
                    padding: 1.8em;

                    p.award-blurb {
                        margin-top: 2.4em;
                    }
                }
            }
        }
    }
}