div.projects .container {
  padding: 1.5em;
}
div.projects .container .project {
  margin-bottom: 4em;
}
div.projects .container .project .project-card {
  background-color: #023868;
  border-radius: 10px;
  color: white;
  padding: 1em;
  margin-bottom: 1em;
  transition: 0.2s;
}
div.projects .container .project .project-card .title-row {
  display: flex;
  margin-top: 0.2em;
}
div.projects .container .project .project-card .title-row h3.project-title {
  margin: 0;
}
div.projects .container .project .project-card .title-row a.project-github {
  margin-left: 1.5em;
}
div.projects .container .project .project-card .title-row a.project-github img {
  width: 1.3em;
  height: 1.3em;
}
div.projects .container .project .project-card .title-row a.project-link {
  margin-left: 1em;
}
div.projects .container .project .project-card .title-row a.project-link img {
  width: 1.3em;
  height: 1.3em;
}
div.projects .container .project .project-card .project-blurb {
  color: #D6D6D6;
  margin: 2em 0;
}
div.projects .container .project .project-card .project-icons .project-icon {
  width: 1.7em;
  height: 1.7em;
  margin-right: 0.5em;
}
div.projects .container .project .project-card:hover {
  background-color: #00539b;
  transition: 0.2s;
}
div.projects .container .project .project-img {
  border-radius: 10px;
  width: 100%;
  filter: brightness(0.8);
  transition: 0.2s;
}
div.projects .container .project .project-img:hover {
  filter: brightness(1);
  transition: 0.2s;
}

@media only screen and (min-width: 800px) {
  div.projects .container {
    padding: 5.5em;
  }
  div.projects .container .project {
    margin-bottom: 4em;
    display: flex;
  }
  div.projects .container .project .project-card {
    width: 50%;
    padding: 1.2em;
    margin-bottom: 1em;
  }
  div.projects .container .project .project-card .title-row {
    margin-top: 0.2em;
  }
  div.projects .container .project .project-card .project-blurb {
    margin: 3em 0;
    width: 70%;
  }
  div.projects .container .project .project-img {
    width: 60%;
    position: absolute;
    top: 48%;
    right: 0;
    transform: translate(0%, -50%);
    z-index: 1;
  }
}
@media only screen and (min-width: 1080px) {
  div.projects .container {
    padding: 7.5em;
    max-width: 1080px;
    margin: auto;
  }
  div.projects .container .project {
    margin-bottom: 12em;
  }
  div.projects .container .project .project-card .project-blurb {
    width: 100%;
  }
  div.projects .container .project .project-img {
    width: 60%;
    z-index: -1;
  }
}/*# sourceMappingURL=projects.css.map */