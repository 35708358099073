$bg: #002E56;
$accent: #62E3FF;
$primary: #fff;
$secondary: #BEBEBE;
$tertiary: #A6A6A6;
$brighter: #D6D6D6;
$bg-2: #003F77;
$bg-3: #023868;
$hover-bg: #00539b;

div.projects {

    .container {
        padding: 1.5em;

        .project {
            margin-bottom: 4em;

            .project-card {
                background-color: $bg-3;
                border-radius: 10px;
                color: white;
                padding: 1em;
                margin-bottom: 1em;
                transition: 0.2s;

                .title-row {
                    display: flex;
                    margin-top: 0.2em;

                    h3.project-title {
                        margin: 0;
                    }

                    a.project-github {
                        margin-left: 1.5em;

                        img {
                            width: 1.3em;
                            height: 1.3em;
                        }

                    }

                    a.project-link {
                        margin-left: 1.0em;

                        img {
                            width: 1.3em;
                            height: 1.3em;
                        }

                    }
                }

                .project-blurb {
                    color: $brighter;
                    margin: 2em 0;

                }

                .project-icons {

                    .project-icon {
                        width: 1.7em;
                        height: 1.7em;
                        margin-right: 0.5em;
                
                    }


                }

                &:hover {
                    background-color: $hover-bg;
                    transition: 0.2s;
                }



            }

            .project-img {
                border-radius: 10px;
                width: 100%;
                filter: brightness(0.8);
                transition: 0.2s;

                &:hover {
                    filter: brightness(1);
                    transition: 0.2s;
                }
            }
        }
    }
}


@media only screen and (min-width: 800px) {

    div.projects {

        .container {
            padding: 5.5em;

            .project {
                margin-bottom: 4em;
                display: flex;

                .project-card {

                    width: 50%;
                    padding: 1.2em;
                    margin-bottom: 1em;


                    .title-row {
                        margin-top: 0.2em;
                    }

                    .project-blurb {
                        margin: 3em 0;
                        width: 70%;

                    }
                }

                .project-img {


                    width: 60%;
                    position: absolute;
                    top: 48%;
                    right: 0;
                    transform: translate(0%, -50%);
                    z-index: 1;
                }
            }
        }
    }
}


@media only screen and (min-width: 1080px) {

    div.projects {

        .container {
            padding: 7.5em;
            max-width: 1080px;
            margin: auto;

            .project {
                margin-bottom: 12em;

                .project-card {

                    .project-blurb {
                        width: 100%;
                    }
                }

                .project-img {

                    width: 60%;
                    z-index: -1;
                }
            }
        }
    }
}