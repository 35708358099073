div.hero .container {
  padding: 0 1.5em;
  height: 100vh;
  margin-top: -17.5vh !important;
  align-items: center;
  display: flex;
}
div.hero .container .greeting {
  margin-left: 0.3em;
  color: #62E3FF;
  font-family: "Hack";
}
div.hero .container .name {
  color: white;
  font-size: 8vw;
  margin: 0;
}
div.hero .container .hero-sentence {
  color: #BEBEBE;
  font-size: 6.6vw;
  margin: 0.5em 0 1em 0;
}
div.hero .container .desc {
  color: #BEBEBE;
  line-height: 1.5;
}
div.hero .container a {
  text-decoration: none;
  color: #62E3FF;
  font-size: 1.1em;
  font-family: "Hack";
}
div.hero .container a .github-cta {
  border: 2px solid #62E3FF;
  color: #62E3FF;
  display: inline-block;
  padding: 0.6em 0.8em;
  margin-top: 1.6em;
  transition: 0.2s;
}
div.hero .container a .github-cta:hover {
  background-color: #004683;
  transition: 0.2s;
}

@media only screen and (min-width: 650px) {
  div.hero .container {
    height: 100vh;
    margin-top: -6.3em !important;
    align-items: center;
    display: flex;
  }
  div.hero .container .greeting {
    margin-left: 0.5em;
  }
  div.hero .container .name {
    font-size: 6vw;
  }
  div.hero .container .hero-sentence {
    font-size: 5.2vw;
  }
}
@media only screen and (min-width: 800px) {
  div.hero .container {
    padding: 0 6.5em;
    max-width: 1080px;
    margin: auto;
  }
  div.hero .container .greeting {
    margin-bottom: 1.2em;
  }
  div.hero .container .hero-sentence {
    margin: 0.2em 0 0.5em 0;
  }
  div.hero .container .desc {
    margin: 0 0 1.5em 0;
    width: 80%;
  }
}
@media only screen and (min-width: 1200px) {
  div.hero .container .content-container .name {
    font-size: 4.5em;
  }
  div.hero .container .content-container .hero-sentence {
    font-size: 3.9em;
  }
}/*# sourceMappingURL=hero.css.map */