div.section-header {
  text-align: center;
  margin: 5em auto 2em auto;
}

h2.section-title {
  width: 88%;
  text-align: center;
  border-bottom: 1px solid #A1A1A1;
  line-height: 0.1em;
  margin: auto;
}

h2.section-title span {
  background: #002E56;
  padding: 0 1.5rem;
  color: #fff;
}

@media only screen and (min-width: 800px) {
  div.section-header {
    max-width: 1420px;
    margin: 8em auto 4em auto;
  }
  h2.section-title {
    width: 76%;
  }
}/*# sourceMappingURL=section-header.css.map */