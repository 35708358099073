$bg: #002E56;
$accent: #62E3FF;
$primary: #fff;
$secondary: #BEBEBE;
$tertiary: #A6A6A6;
$brighter: #D6D6D6;
$bg-2: #003F77;
$btn-hover: #004683;



.navbar {
    padding: 1em;
    margin: auto;

    .container {
        display: flex;
        place-content: space-between;

        .nav-left {
            display: flex;

            img#navbar-pfp {
                width: 3.5em;
                height: 3.5em;
                border-radius: 50%;
                border: 4px solid $accent;
            }
        }

        .mobile-menu {
            width: 2.5em;
            height: 2.5em;
            margin-top: auto;
            margin-bottom: auto;
            cursor: pointer;
        }

        nav.menu-btn {
            display: block !important;
        }

        nav {
            display: none;

            position: fixed;
            z-index: 1000;
            width: 50%;
            right: 0;
            top: 0;
            height: 100vh;
            padding: 1em;
            background-color: $bg-2;

            ul {
                margin-top: 6em;
            }

            li {
                list-style: none;
                text-align: right;

                a {
                    text-decoration: none;
                    display: inline-block;
                    color: white;
                    margin: 0.5em auto;
                    font-size: 1.3em;
                    font-weight: normal;
                    transition: 0.4s;

                    &:hover {
                        transition: 0.4s;
                        color: $accent;
                    }
                }
            }

            .resume-cta {
                transition: 0.2s;
                text-align: right;
                text-decoration: none;
                font-family: 'Hack';
                margin-top: 2em !important;

                a {
                    color: $accent;
                }
            }

            .mobile-menu-exit {
                float: right;
                display: block;
                width: 2.5em;
                height: 2.5em;
                margin-top: 1em;
                margin-bottom: auto;
                cursor: pointer;
            }

            ul.primary-nav > * {
                margin-top: auto;
                margin-bottom: auto;
            }

        }
    }
}

@media only screen and (min-width: 800px) {

    .mobile-menu, .mobile-menu-exit {
        display: none !important;
    }

    .navbar .container {
        display: flex;
    }

    .navbar .container .nav-left {
        margin-top: auto;
        margin-bottom: auto;
    }

    .navbar .container nav {
        display: flex;
        justify-content: space-between;
        background: none;
        position: unset;
        width: auto;
        height: auto;
        padding: 0;
        margin-top: auto;
        margin-bottom: auto;

        ul {
            display: flex;
            margin: auto;
        }

        li {
            list-style: none;

            a {
                font-family: 'Hack';
                text-decoration: none;
                color: $primary;
                font-size: 1em;
                padding: .1em 1em;
            }
        }

        .resume-cta {
            border: 2px solid $accent;
            border-radius: 2px;
            margin-left: 1em;
            margin-top: auto !important;
            transition: 0.2s;
            text-decoration: none;
            font-family: 'Hack';
            font-size: 1em;

            a {
                padding: 0.8em 0.9em;
                margin: 0;
            }

            &:hover {
                background-color: $btn-hover;
                transition: 0.2s;
            }
        }
    }
}