div.about .container {
  padding: 1.5em;
  margin: auto;
  max-width: 770px;
}
div.about .container div.about-text p {
  line-height: 1.5;
  color: #BEBEBE;
}
div.about .container div.about-text p span {
  color: #62E3FF;
}
div.about .container div.picture-container {
  margin: auto;
  text-align: center;
}
div.about .container img#picture {
  margin-top: 2em;
  width: 13rem;
  height: 13rem;
  border: 2px solid #62E3FF;
  border-radius: 5px;
}

@media only screen and (min-width: 800px) {
  div.about .container {
    padding-left: 6.5em;
    padding-right: 6.5em;
    display: flex;
  }
  div.about .container div.about-text {
    margin-right: 5em;
  }
  div.about .container img#picture {
    margin-top: 0;
  }
}/*# sourceMappingURL=about.css.map */