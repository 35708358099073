$bg: #002E56;
$accent: #62E3FF;
$primary: #fff;
$secondary: #BEBEBE;
$tertiary: #A6A6A6;
$brighter: #D6D6D6;
$bg-2: #003F77;



div.experience {

    .container {
        padding: 1.5em;

        .experience-card {
            display: flex;
            margin-top: 4em;

            .experience-img {
                margin-right: 1.2em;

                img {
                    width: 4.5em;
                    height: 4.5em;
                }

            }

            .experience-text {
                font-family: 'Hack';


                h3.experience-title {
                    font-weight: bold;
                    margin: 0;
                    color: $primary;
                }

                p.experience-company {
                    color: $secondary;
                    margin-top: 0.7em;
                    margin-bottom: 0.7em;
                    font-family: 'Inter';
                    font-weight: 500;
                }

                p.experience-date {
                    color: $accent;
                    margin-top: 0;
                    margin-bottom: 1.0em;
                }


                .experience-pt {
                    display: flex;
                    font-family: 'Inter';
                    text-align: left;

                    img {
                        margin: auto 0.55em auto 0;
                        width: 2em;
                        height: 2em;
                    }
                    
                    p {
                        margin: 0.7em auto;
                        color: $tertiary;
                        line-height: 1.4;
                        width: 100%;

                        a {
                            color: $accent;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 600px) {

    div.experience {

        .container {

            .experience-card {

                .experience-img {
                    margin-right: 1.5em;

                    img {
                        width: 6em;
                        height: 6em;
                    }

                }
            }
        }
    }
}


@media only screen and (min-width: 800px) {

    div.experience {

        .container {
            padding: 6.5em;
            max-width: 900px;
            margin: auto;


            .experience-card {
                display: flex;
                margin-top: 2em;
                margin-bottom: 6em;
                justify-content: center;

                .experience-img {
                    margin-right: 2em;

                    img {
                        width: 8em;
                        height: 8em;

                    }

                }

                .experience-text {

                    h3.experience-title {
                        font-size: 1.5em;
                    }

                    p.experience-company {
                        margin-top: 0.8em;
                        margin-bottom: 0.8em;
                        font-size: 1.2em;
                    }

                    p.experience-date {
                        font-size: 1.1em;
                    }

                    .experience-pt {
                        margin-bottom: 0.0em;
                        
                        p {
                            margin: 0.65em;
                        }
                    }
                }
            }
        }
    }
}