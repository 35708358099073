div.experience .container {
  padding: 1.5em;
}
div.experience .container .experience-card {
  display: flex;
  margin-top: 4em;
}
div.experience .container .experience-card .experience-img {
  margin-right: 1.2em;
}
div.experience .container .experience-card .experience-img img {
  width: 4.5em;
  height: 4.5em;
}
div.experience .container .experience-card .experience-text {
  font-family: "Hack";
}
div.experience .container .experience-card .experience-text h3.experience-title {
  font-weight: bold;
  margin: 0;
  color: #fff;
}
div.experience .container .experience-card .experience-text p.experience-company {
  color: #BEBEBE;
  margin-top: 0.7em;
  margin-bottom: 0.7em;
  font-family: "Inter";
  font-weight: 500;
}
div.experience .container .experience-card .experience-text p.experience-date {
  color: #62E3FF;
  margin-top: 0;
  margin-bottom: 1em;
}
div.experience .container .experience-card .experience-text .experience-pt {
  display: flex;
  font-family: "Inter";
  text-align: left;
}
div.experience .container .experience-card .experience-text .experience-pt img {
  margin: auto 0.55em auto 0;
  width: 2em;
  height: 2em;
}
div.experience .container .experience-card .experience-text .experience-pt p {
  margin: 0.7em auto;
  color: #A6A6A6;
  line-height: 1.4;
  width: 100%;
}
div.experience .container .experience-card .experience-text .experience-pt p a {
  color: #62E3FF;
}

@media only screen and (min-width: 600px) {
  div.experience .container .experience-card .experience-img {
    margin-right: 1.5em;
  }
  div.experience .container .experience-card .experience-img img {
    width: 6em;
    height: 6em;
  }
}
@media only screen and (min-width: 800px) {
  div.experience .container {
    padding: 6.5em;
    max-width: 900px;
    margin: auto;
  }
  div.experience .container .experience-card {
    display: flex;
    margin-top: 2em;
    margin-bottom: 6em;
    justify-content: center;
  }
  div.experience .container .experience-card .experience-img {
    margin-right: 2em;
  }
  div.experience .container .experience-card .experience-img img {
    width: 8em;
    height: 8em;
  }
  div.experience .container .experience-card .experience-text h3.experience-title {
    font-size: 1.5em;
  }
  div.experience .container .experience-card .experience-text p.experience-company {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    font-size: 1.2em;
  }
  div.experience .container .experience-card .experience-text p.experience-date {
    font-size: 1.1em;
  }
  div.experience .container .experience-card .experience-text .experience-pt {
    margin-bottom: 0em;
  }
  div.experience .container .experience-card .experience-text .experience-pt p {
    margin: 0.65em;
  }
}/*# sourceMappingURL=experience.css.map */