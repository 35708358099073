$bg: #002E56;
$accent: #62E3FF;
$primary: #fff;
$secondary: #BEBEBE;
$tertiary: #A6A6A6;
$brighter: #D6D6D6;
$bg-2: #003F77;
$btn-hover: #004683;


div.hero {

    .container {
        padding: 0 1.5em;
        height: 100vh;
        margin-top: -17.5vh !important;
        align-items: center;
        display: flex;

        .greeting {
            margin-left: 0.3em;
            color: $accent;
            font-family: 'Hack';
        }

        .name {
            color: white;
            font-size: 8vw;
            margin: 0;
        }

        .hero-sentence {
            color: $secondary;
            font-size: 6.6vw;
            margin: 0.5em 0 1em 0;
        }

        .desc {
            color: $secondary;
            line-height: 1.5;
        }

        a {
            text-decoration: none;
            color: $accent;
            font-size: 1.1em;
            font-family: 'Hack';

            .github-cta {
                border: 2px solid $accent;
                color: $accent;
                display: inline-block;
                padding: 0.6em 0.8em;
                margin-top: 1.6em;
                transition: 0.2s;

                &:hover {
                    background-color: $btn-hover;
                    transition: 0.2s;
                }

            }

        }
    }
}

@media only screen and (min-width: 650px) {
    div.hero {
        .container {
            height: 100vh;
            margin-top: -6.3em !important;
            align-items: center;
            display: flex;

            .greeting {
                margin-left: 0.5em;
            }

            .name {
                font-size: 6vw;
            }

            .hero-sentence {
                font-size: 5.2vw;
            }
        }
    }
}


@media only screen and (min-width: 800px) {
    div.hero {
        .container {
            padding: 0 6.5em;
            max-width: 1080px;
            margin: auto;

            .greeting {
                margin-bottom: 1.2em;
            }

            .hero-sentence {
                margin: 0.2em 0 0.5em 0;
            }

            .desc {
                margin: 0 0 1.5em 0;
                width: 80%;
            }
        }
    }
}


@media only screen and (min-width: 1200px) {
    div.hero {
        .container {

            .content-container {

                .name {
                    font-size: 4.5em;
                }

                .hero-sentence {
                    font-size: 3.9em;
                }
            }
        }
    }
}